import { getIp } from "~/fc/Client"
import AuthManager from "~/fc/Auth/AuthManager"
import { getInturruptedJobIds, retryFailedJobs } from '~/fc/Converter/JobHelper'

export const listenForPackageUpdate = async () => {
    const user = $nuxt.$auth.User
    if(getInturruptedJobIds().length > 0) $nuxt.$store.commit('banner/setUpgradingMidConversion', true)

    if(user) 
        $nuxt.$socket.emit("subscribe", `subscription_user.${user.id}`);
    else {
        const ip = await getIp();
        $nuxt.$socket.emit("subscribe", `subscription_userip.${ip}`);
    } 
}

export const handleUserSubscriptionUpdate = async () => {
    const authManager = new AuthManager($nuxt)
    const token = localStorage.getItem('auth._token.local')
    if(token) authManager.softLogIn({token: token})
    $nuxt.$store.commit("banner/clearAllBanners")
    $nuxt.$store.commit('banner/setUpgradingMidConversion', false)
    retryFailedJobs()
}